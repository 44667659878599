<template>
  <div :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'" class="header">
    <div
      v-if="$route.name !== 'subscriberVerify'"
      class="container"
      :class="[mainHeaderClass ? 'header-content' : 'only-search']"
      :style="[providerUniqueId === 'z01x1wPo' ? { padding: '0rem' } : { padding: '0.6rem 0.5rem 0.4rem 0.6rem' }]"
    >
      <div class="header-content-brand" id="smallHeader">
        <ul>
          <li>
            <div class="logo" :class="localDisplayLang === 'ara' ? 'mr' : ''">
              <img
                src="@/assets/icons/NoorPlayLogo.png"
                alt="noor_play_logo"
                class="noorPlay_logo"
                @click="routeHome()"
              />
            </div>
          </li>
          <li v-if="!hideOptions">
            <ul v-if="!hideMenuItems && !appConfig.featureEnabled.isDeckingEnabled" class="header-text">
              <li v-for="(item, index) in menuList" :key="index">
                <p v-if="!item.isRequiredLogin || item.isRequiredLogin && subscriberid" @click="menuSelect(index)">
                  {{ localDisplayLang === "ara" ? item.title.ar : item.title.default.toLowerCase() }}
                </p>
								 
              </li>
            </ul>
						 <ul v-if="!hideMenuItems && appConfig.featureEnabled.isDeckingEnabled" class="header-text">
              <li v-for="(item, index) in menuList" :key="index">
								<p v-if="!item.userTypes.includes(`REGISTERED`) || item.userTypes.includes(`REGISTERED`) && subscriberid" @click="menuSelect(index)">
                  {{ localDisplayLang === "ara" ? item.title.ara : item.title.eng.toLowerCase() }}
                </p>				 
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="header-content-center" id="brandCenter" :class="[showHamBrands ? 'flex' : 'none']">
        <img
          class="hamburger-icon"
          id="hamburger-icon"
          src="@/assets/icons/Menu.svg"
          @click="showMenu"
          alt
          v-if="!hideOptions"
        />
        <img src="@/assets/icons/NoorPlayLogo.png" alt="noor_play_logo" class="noorPlay_logo" @click="routeHome()" />
      </div>
      <div class="header-content-right justifyEnd">
        <div
          class="header-content-right-search"
          id="search-large"
          :class="[showHamBrands ? 'hide-search' : 'show-search']"
          v-if="!hideOptions"
        >
          <div class="header-content-right-search-content">
            <img
              class="header-content-right-search-content-back"
              :class="[showHamBrands ? 'no-back' : 'yes-back']"
              src="@/assets/icons/back.svg"
              @click="coverSmallSearch"
              alt
            />
            <input
              type="text"
              id="searchInput"
              :placeholder="[
                localDisplayLang === 'ara' && appConfig.messagesList.ar
                  ? appConfig.messagesList.ar.searchPlaceHolder
                  : localDisplayLang === 'eng' && appConfig.messagesList.en
                  ? appConfig.messagesList.en.searchPlaceHolder
                  : localDisplayLang === 'ara' || localDisplayLang === 'eng'
                  ? 'Search'
                  : '',
              ]"
              autocomplete="off"
              v-model="searchValue"
              @input="searchInputContentValue($event.target.value)"
            />
            <img src="@/assets/icons/Search.svg" @click="searchInputContent(searchValue)" id="searchIcon" alt />
          </div>
          <Search
            v-if="showSearch"
            @showInputText="showInputText"
            :filterCategory="filterCat"
            :searchQuery="searchValue"
          ></Search>
        </div>
        <div class="header-content-right_locale" @click="changeLanguage()" v-if="enableLanguageOption">
          <img src="@/assets/icons/Globe1.svg" alt />
          <span>{{ localDisplayLang === "ara" ? "English" : "عربى" }}</span>
        </div>
        <div class="header-content-right-auth" :class="[showHamBrands ? 'flex-auth' : 'none-auth']" v-if="!hideOptions">
          <div class="header-content-right-auth-mobile-search" :class="localDisplayLang === 'ara' ? 'mr-r' : 'mr-l'">
            <img src="@/assets/icons/Search.svg" @click="showSmallSearch" />
            <!-- <span v-else class="go-Original" @click="coverSmallSearch">
              <img src="@/assets/icons/back.svg" alt />
            </span>-->
          </div>

          <div v-if="showBellIcon && !isKidsModeOn">
            <!-- Notification bell icon -->
            <div class="py-10 notification-bell" id="noShowNotify" @click="toggleNotificationDrop(!isNotify)">
              <img class="notify-icon" src="@/assets/icons/notification_bell.png" id="notify-drop" alt />
              <div v-if="notificationCount" class="notification-dot">{{ notificationCount }}</div>
            </div>
          </div>

          <div class="header-content-right-auth-nav-btn" v-if="!subscriberid" id="large-notify-info">
            <button
              class="button-primary login"
              :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
              @click="toggleAuthPopUp('lookup')"
            >
             <span class="btn-txt-color"> {{ $t("login") }} / {{ $t("signup") }} </span>
            </button>
          </div>
          <div class="header-content-right-auth-info" v-else>
            <ul class="header-content-logged-info">
              <!-- <li class="py-10" id="noShowNotify" @click="toggleNotificationDrop">
              <img
                class="notify-icon"
                src="@/assets/icons/notification_bell.png"
                id="notify-drop"
                alt
              />
              </li> -->
              <li id="noShowProfile">
                <div id="profile-pic">
                  <img
                    class="profile-icon"
                    :src="
                      subscriberProfileDetails
                        ? subscriberProfileDetails.picture
                        : subscriberDetails
                        ? subscriberDetails.picture
                        : null
                    "
                    v-if="
                      subscriberProfileDetails
                        ? subscriberProfileDetails.picture
                        : subscriberDetails
                        ? subscriberDetails.picture
                        : null
                    "
                    style="border-radius:50%;"
                    alt
                  />
                  <img v-else class="profile-icon" src="@/assets/icons/ProfileDp.png" id="profile-drop" alt />
                </div>
                <div class="profile-info py-10" v-if="isEnabled">
                  <!-- <p>{{ subscriberDetails.profilename }}</p> -->
                  <p>{{ getSubscriberProfileName }}</p>
                  <span
                    v-if="
                      subscriberDetails &&
                        subscriberProfileDetails &&
                        subscriberDetails.subscriberid === subscriberProfileDetails.profileid
                    "
                    >ADMIN</span
                  >
                </div>
                <div class="arrow-div">
                  <img
                    src="@/assets/icons/down_arrow.svg"
                    id="arrow"
                    @click="
                      toggleProfileDrop();
                      rotate('arrow');
                    "
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="header-verification container">
    <img src="@/assets/icons/mobiotics_logo.svg" alt v-if="providerid === 'manishkr'"/>-->
    <!-- <img src="@/assets/icons/Protel_Media_Horz.png" alt v-if="providerid === 's9efci0q'"/> -->
    <!-- <img src="@/assets/icons/NoorPlayLogo.png" class="noorPlay_logo" alt=""> -->
    <!-- </div>  -->
    <transition>
      <AuthPopup v-if="showAuthPopup" :formInfo="authInfo" :closePopup="() => closeAuthPopup(false)" />
    </transition>
    <transition>
      <!-- Error Popup -->
      <ErrorMessage
        v-if="showErrorMessagePopup"
        :messageData="messageData"
        :closePopup="() => toggleErrorMessagePopup({ state: false, messageData: null })"
      />
    </transition>
    <transition>
      <ForgotPassword
        v-if="showForgotPassword"
        :input="forgotPasswordInput"
        :inputtype="forgotPasswordInputtype"
        :closePopup="() => closeForgotPassword(false)"
      />
    </transition>
    <transition>
      <NotificationDrop v-if="isNotify" />
    </transition>
    <transition>
      <ProfileDrop v-if="isProfile" />
    </transition>
    <transition>
      <facebookEmail
        v-if="isFacebookEmail && facebookEmailPayload"
        :payload="facebookEmailPayload"
        :closePopup="() => toggleFacebookEmail(false)"
      />
    </transition>
    <transition>
      <sharedContent
        v-if="isSharedContent"
        :objectId="sharedContentId"
        :closePopup="() => toggleSharedContent(false)"
      />
    </transition>
    <transition>
      <switchProfilePin v-if="isSwitchProfile" :profile="profileInfo" :closePopup="() => toggleSwitchProfile(false)" />
    </transition>
    <transition>
      <payment v-if="isPayment" :paymentDetail="paymentDetail" :closePopup="() => togglePaymentPopup(false)" />
    </transition>
    <transition>
      <NoorPayment
        v-if="isNoorPayment"
        :paymentDetail="noorPaymentDetail"
        :closePopup="() => toggleNoorPayment(false)"
      />
    </transition>
    <transition>
      <checkout
        v-if="isCheckout"
        :checkoutDetails="checkoutPaymentDetails"
        :closePopup="() => toggleCheckoutPopup(false)"
      />
    </transition>
    <transition>
      <subscribe
        v-if="isSubscribe"
        :key="subscriberid"
        :subscribePayload="subscribepayload"
        :closePopup="() => toggleSubscribePopup(false)"
      />
    </transition>
    <transition>
      <mobileMenu
        :subscriptions="subscriptions"
        class="mobile-menu"
        v-if="isMobile"
        :menu="menuList"
        @close="closeMobileMenu"
      ></mobileMenu>
    </transition>
    <transition>
      <contentShare
        v-if="showshareContent"
        :content="shareContentDetails"
        :closePopup="() => toggleContentSharePopup(false)"
      ></contentShare>
    </transition>
    <transition>
      <overlayLight v-if="showOverlay"></overlayLight>
    </transition>
    <transition>
      <contentDetail
        v-if="isContentDetail"
        :content="contentDetailProp"
        :playerInstance="playerInstance"
        :closePopup="() => toggleContentDetail(false)"
      ></contentDetail>
    </transition>
    <transition>
      <telcoOtp
        v-if="isTelcoOTP"
        :paymentInfo="telcoPaymentInfo"
        :planInfo="telcoPlanInfo"
        :isMobileno="telcoMobileNumber"
        :gatewayInfo="telcoGatewayInfo"
        :closePopup="() => toggleTelcoOTP(false)"
      >
      </telcoOtp>
    </transition>

    <transition>
      <SubscriptionCancelReason v-if="isCancelReasonPopup" :closePopup="() => toggleCancelReasonPopup(false)" />
    </transition>

    <transition>
      <SubscriptionSuccessMessage :transactionResponse="transactionDetails" v-if="isShowSubscriptionPopup" :closePopup="() => toggleSuccessMessagePopup(false)" />
    </transition>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Utility from "@/mixins/Utility.js";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import cleverTap from "@/mixins/clevertap.js";
import branchIO from "@/mixins/branchEvents.js";
import FirebaseActions from "@/mixins/FirebaseActions.js";
import moment from "moment";
import { _providerId } from "@/provider-config.js";
import firebase from "firebase";
import "firebase/messaging";
import { showSnackBar } from '../../utilities';

export default {
  props: {
    showDeepPlan: {
      type: Boolean,
    },
    playerInstance: {
      type: Object,
    },
    isAuth: {
      type: Boolean,
    },
  },
  data() {
    return {
      headerClass: true,
      isActive: "header-content",
      notActive: "only-search",
      dynamicSearchPlaceholder: "",
      isSmallSearch: true,
      searchCat: "All",
      filterCat: null,
      isShow: false,
      searchValue: "",
      showSearchDrop: false,
      showSearch: false,
      showAuthButtons: true,
      showAuthPopup: false,
      showForgotPassword: false,
      forgotPasswordDefaultId: null,
      isProfile: false,
      isNotify: false,
      activeTab: "active-tab",
      selectedSection: null,
      isFacebookEmail: false,
      forgotPasswordInput: "",
      forgotPasswordInputtype: "",
      subscriberDetails: null,
      authInfo: {},
      getSearch: [],
      isSharedContent: false,
      showshareContent: false,
      sharedContentId: null,
      shareContentDetails: null,
      isSwitchProfile: false,
      profileInfo: {},
      isPayment: false,
      paymentDetail: null,
      isSubscribe: false,
      subscribepayload: {},
      isMobile: false,
      hideSmallSearch: false,
      showOverlay: false,
      net5Logo: require("@/assets/icons/Net5-Logo.png"),
      showSmallBack: false,
      providerUniqueId: _providerId,
      isNoorPayment: false,
      noorPaymentDetail: null,
      isCheckout: false,
      checkoutPaymentDetails: null,
      isContentDetail: false,
      contentDetailProp: null,
      menuList: [],
      showHamBrands: true,
      mainHeaderClass: true,
      localDisplayLang: null,
      subscriptionDataMenu: null,
      enableLanguageOption: false,
      isEnabled: false,
      subscriptions: [],
      hideOptions: false,
      isTelcoOTP: false,
      telcoPaymentInfo: null,
      telcoGatewayInfo: null,
      telcoPlanInfo: null,
      telcoMobileNumber: false,

      showBellIcon: false,
      notificationCount: 0,

      isCancelReasonPopup: false,

      subscriberProfileDetails: null,

      hideMenuItems: false,
      showErrorMessagePopup: false,
      messageData: null,

      isKidsModeOn: false,

      transactionDetails: null,
      isShowSubscriptionPopup : false,
			menuListArr : [],
			appconfigLocal: null,
			deckingEnabled: false
    };
  },
  computed: {
    ...mapGetters(["appConfig", "subscriberid", "getRtl", "subscriptionList", "isMovieEnabled", "profileid", "getPlanDeeplinkPath"]),

    getSubscriberProfileName() {
      if (
        this.subscriberProfileDetails &&
        this.subscriberProfileDetails.profilename &&
        this.subscriberProfileDetails.profilename.length > 10 &&
        this.localDisplayLang === "eng"
      ) {
        return `${this.subscriberProfileDetails.profilename.substring(0, 10)}...`;
      } else if (
        this.subscriberProfileDetails &&
        this.subscriberProfileDetails.profilename &&
        this.subscriberProfileDetails.profilename.length > 10 &&
        this.localDisplayLang === "ara"
      ) {
        return `...${this.subscriberProfileDetails.profilename.substring(0, 10)}`;
      } else if (this.subscriberProfileDetails && this.subscriberProfileDetails.profilename) {
        return this.subscriberProfileDetails.profilename;
      } else if (
        this.subscriberDetails.profilename &&
        this.subscriberDetails.profilename.length > 10 &&
        this.localDisplayLang === "eng"
      ) {
        return `${this.subscriberDetails.profilename.substring(0, 10)}...`;
      } else if (
        this.subscriberDetails.profilename &&
        this.subscriberDetails.profilename.length > 10 &&
        this.localDisplayLang === "ara"
      ) {
        return `...${this.subscriberDetails.profilename.substring(0, 10)}`;
      } else {
        return this.subscriberDetails.profilename;
      }
    },
  },
  watch: {
    $route(to) {
      console.log("from $route", to);
      this.isProfile = false;

      // console.log("THE REAL ROUTE -- > ", this.$route)

      // if(this.$route.path === "/switchProfile" || this.$route.path === "/addProfile") {
      //   this.hideMenuItems = true;
      // }

      let route = this.$router.currentRoute;
      if (
        route.name === "partner-switching" ||
        route.name == "telcoPaymentHandler" ||
        route.name == "addProfile" ||
        route.name == "switchProfile" ||
        route.name == "voucher" 
      ) {
        this.hideOptions = true;
      } else {
        this.hideOptions = false;
      }
    },
    subscriberid(val) {
      if (val) {
        this.getSubscriberDetails();
        this.getSubscriptionList();
      }
    },
    isMovieEnabled(val) {
      if (val) {
        // this.filterMovieMenu();
      }
    },
    screenWidth(val) {
      ("val", val);
    },
    subscriptionList(val) {
      if (val.length > 0) {
        // this.fetchDataItem();
      }
    },
    // searchValue(data) {
    //   let timer;
    //   clearTimeout(timer);

    //   timer = setTimeout(() => {
    //     clearTimeout(timer);
    //     this.searchInputContent(data);
    //   }, 2000);
    // },
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    if (firebase.messaging.isSupported()) {
      this.showBellIcon = true;
    }

    eventBus.$on("getNotificationsHeaderCount", () => {
      this.getNotificationCount();
    });

    eventBus.$on("close-payment-popup", () => {
      this.togglePaymentPopup(false);
    });


    //disable header options if landing page.
    let route = this.$router.currentRoute;

    if (route.name == "partner-switching" || route.name == "telcoPaymentHandler") {
      this.enableLanguageOption = true;
      this.isEnabled = true;
    } else if (screen.width < 576) {
      this.enableLanguageOption = false;
      this.isEnabled = false;
    } else {
      this.enableLanguageOption = true;
      this.isEnabled = true;
    }

    // disable header options if landing page.
    if (
      route.name === "partner-switching" ||
      route.name == "telcoPaymentHandler" ||
      route.name == "addProfile" ||
      route.name == "switchProfile" ||
       route.name == "voucher" || 
        route.name == "SubscriptionHandler" 
    ) {
      this.hideOptions = true;
    }
    if (
       route.name == "voucher"
    ) {
      this.enableLanguageOption = false;
    }

    this.filterMenuItems();

    if(this.subscriberProfileDetails && this.subscriberProfileDetails.kidsmode === "YES") {
      this.isKidsModeOn = true;
    } else {
      this.isKidsModeOn = false;
    }

    if (this.subscriberid) {
      this.getSubscriptionList();
    }

    // else {
    //   this.menuList = this.menuList.filter((menu) => {
    //     if (menu.title.default !== "Subscriptions") {
    //       return menu;
    //     }
    //   });

    //   console.log("MENU LIST -- 2 -- ", this.menuList)
    // }

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
		eventBus.$on("rerender-menus", (data) => {
			if (this.subscriberid) {
      this.getSubscriptionList();
    }
      this.filterMenuItems();
    });
    eventBus.$on("hideHeaderOptions", () => {
      this.hideOptions = true;
      this.enableLanguageOption = true;
    });

    eventBus.$on("switchHeaderBack", () => {
      this.hideOptions = false;

      if (screen.width >= 1024) {
        this.enableLanguageOption = true;
      } else {
        this.enableLanguageOption = false;
      }
    });

    eventBus.$on("toggle-cancel-reason-popup", (data) => {
      this.toggleCancelReasonPopup(data);
    });

    this.getSubscriberDetails();

    if (this.showDeepPlan) {
      let payload = {
        listPlans: "ALL",
      };
      this.subscribepayload = payload;
      this.isSubscribe = true;
    }
    // window.addEventListener("resize", this.checkScreenWidth);

    window.dispatchEvent(window.branchLoaded);
    window.dispatchEvent(window.facebookLoaded);
  },
  destroyed() {
    // window.addEventListener("resize", this.checkScreenWidth);`
    // window.removeEventListener("mouseup";
  },
  mounted() {
		 this.appconfigLocal = localStorage.getItem("appConfig") ? JSON.parse(localStorage.getItem("appConfig")) : '',
		 this.deckingEnabled = this.appconfigLocal.featureEnabled.isDeckingEnabled ;
		 //alert(this.deckingEnabled)
    // if(localStorage.getItem("showSubscriptionPlans")){
    //   console.log('did it reach to this block--------------------------')
    //   this.isSubscribe = true
    // }
    if (this.isAuth && this.$router.currentRoute.name == "Home") {
      this.toggleAuthPopUp("lookup");
    }

    // Get new Notifications count
    this.getNotificationCount();

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.actChangeMetaData(this.localDisplayLang);

    this.dynamicSearchPlaceholder = this.$t("Search Shows and Movies");

    this.deepLinking();

    this.getBranchDeepLinks();

    eventBus.$on("switchProfile", (response) => {
      this.profileInfo = response;
      this.toggleSwitchProfile(true);
    });

    eventBus.$on("logout-success", () => {
      if (localStorage.getItem("loginType")) {
        let loginType = JSON.parse(localStorage.getItem("loginType"));
        if (loginType.type && loginType.type === "FB") {
          FB.logout(function(response) {
            // Person is now logged out
          });
        }
      }
      //logging google event on successful logout.
      let GA = {
        status: "SUCCESS",
      };
      this.logoutEvent(GA);

      //calling utility function to clear localstorage details.
      this.logoutCB();
    });

    eventBus.$on("close-notification-drop", (state) => {
      // if (state) {
      this.toggleNotificationDrop(state);
      // }
    });

    eventBus.$on("showForgotPassword", (response) => {
      this.forgotPasswordInput = response.input;
      this.forgotPasswordInputtype = response.inputtype;
      this.toggleForgotPassword(true);
    });

    eventBus.$on("toggleSuccessMessagePopupTrigger", (response) => {
      // this.isShowSubscriptionPopup = true;
      this.toggleSuccessMessagePopup(true);
      this.transactionDetails = response;
    });

    eventBus.$on("toggleSuccessMessagePopupClose", (val) => {
      this.toggleSuccessMessagePopup(val);
    });

    eventBus.$on("close-forgot-password-popup", () => {
      this.closeForgotPassword(false);
    });

    eventBus.$on("facebookemail", (response) => {
      this.toggleFacebookEmail(true);
      this.facebookEmailPayload = response;
    });

    eventBus.$on("closeFacebookEmail", (val) => {
      this.toggleFacebookEmail(val);
    });

    eventBus.$on("paymentPopup", (response) => {
      this.paymentDetail = response;
      this.togglePaymentPopup(response.state);
    });

    eventBus.$on("noorPayments", (response) => {
      this.noorPaymentDetail = response;
      this.toggleNoorPayment(response.state);
    });

    eventBus.$on("showCheckout", (response) => {
      this.checkoutPaymentDetails = response;
      this.toggleCheckoutPopup(true);
    });

    eventBus.$on("showAuthorizeErrorMessage", (response) => {
      this.toggleErrorMessagePopup(response);
    });

    eventBus.$on("subscribePopup", (response) => {
      console.log("THIS IS SUBSCRIBE POPUP EVENT BUS ON -----", response);
      console.log("toggle popup =====================");
      this.toggleSubscribePopup(true);
      this.subscribepayload = response;
    });

    eventBus.$on("showContentDetail", (response) => {
      this.contentDetailProp = response.content;
      this.toggleContentDetail(response.state);
    });

    eventBus.$on("telcoOtpPopup", (response) => {
      console.log("oreedo response in header", response);
      this.telcoPaymentInfo = response.paymentInfo;
      this.telcoPlanInfo = response.planInfo;
      this.telcoGatewayInfo = response.gatewayInfo;
      this.telcoMobileNumber = response.isMobileno;
      this.toggleTelcoOTP(response.state);
    });

    eventBus.$on("toggle-SearchInput", (state) => {
      this.showSearch = false;
      eventBus.$emit("show-light-overlay", false);

      if (this.searchValue) {
        this.searchValue = null;
      }
    });

    eventBus.$on("search-category-field", this.searchSelect);

    eventBus.$on("toggle-MenuDrop", (state) => {
      this.isShow = state;
    });

    eventBus.$on("toggle-profile-drop", (state) => {
      this.isProfile = state;
      if (document.getElementById("arrow")) {
        document.getElementById("arrow").classList.add("rotate-reverse");
      }
    });

    eventBus.$on("show-content-share", (response) => {
      this.showshareContent = response.popupState;
      this.shareContentDetails = response.content;
    });

    eventBus.$on("social-login-event", (response) => {
      console.log("THE SOCIAL response ------", response);

      let GA = {
        login_mode: response.app == "FB" ? "Facebook" : response.app,
        status: "Success",
      };

      const browser = this.getPlatformType();

      GA.platform = this.getPlatform();

      GA.platform_type = browser.browser;

      this.loginEvent(GA);
    });

    eventBus.$on("invoke-subscriber-details", (response) => {
      let data = {
        Name: response.profilename ? response.profilename : "",
        Identity: response.profileid ? response.profileid : "",
        Email: response.email ? response.email : "",
        Phone: response.mobileno ? response.mobileno : "",
        Gender: response.gender ? (response.gender === "MALE" ? "M" : "F") : "",
        DOB: response.dob ? moment(response.dob).format("DD-MM-YYYY") : "",
        Photo: response.picture ? response.picture : "",
        subscriber_id: response.subscriberid,
        "MSG-email": true,
        "MSG-push": true,
        "MSG-sms": true,
      };

      //clevertap user login sdk event.
      this.loginCleverEvent(data);

      let branch_login_data = {
        Subscriber_ID: response.subscriberid ? response.subscriberid : "",
        Name: response.profilename ? response.profilename : "",
        Gender: response.gender ? response.gender : "",
        Email: response.email ? response.email : "",
        Phone: response.mobileno ? parseInt(response.mobileno) : "",
        DOB: response.dob ? response.dob : "",
        Age: response.age ? parseInt(response.age) : "",
        Country: "IN",
      };

      //branch user login event
      this.loginBranchEvent(branch_login_data);
    });

    eventBus.$on("profile-switched", (response) => {
      let data = {
        Name: response.profilename ? response.profilename : "",
        Identity: response.profileid ? response.profileid : "",
        // "Email": "",
        // "Phone": "",
        Gender: response.gender ? response.gender : "",
        DOB: response.dob ? response.dob : "",
        Photo: response.picture ? response.picture : "",
        subscriber_id: response.subscriberid,
        "MSG-email": true,
        "MSG-push": true,
        "MSG-sms": true,
      };

      this.loginCleverEvent(data);
    });
  },
  methods: {
    ...mapMutations(["setToken", "setRtl", "setSubscriptionList"]),

    ...mapActions(["listSubscription"]),

    searchInputContentValue(value) {
      let timer;
      clearTimeout(timer);

      timer = setTimeout(() => {
        clearTimeout(timer);
        this.searchInputContent(value);
      }, 2000);
    },
		filterMenuItemsingDecking() {
      let dataset = localStorage.getItem("appDeckingConfig") ? JSON.parse(localStorage.getItem("appDeckingConfig")) : '';
      let menuItemsList = dataset.screens;
			console.log("menuItemsList",menuItemsList)

      let currentProfileDetails = localStorage.getItem(
        "subscriberProfileDetails"
      );

      if (currentProfileDetails) {
        currentProfileDetails = JSON.parse(currentProfileDetails);
        this.subscriberProfileDetails = currentProfileDetails;
      }

     // const menuListArr = [];
     this.menuListArr = []

      menuItemsList.forEach((menu) => {
        if (
          this.subscriberid && 
          this.subscriberProfileDetails &&
          this.subscriberProfileDetails.kidsmode === "YES"
        ) {
          if (menu.profileTypes && menu.status === "ACTIVE" && (menu.profileTypes.includes("KIDS") || menu.profileTypes.includes("ALL"))) {
            this.menuListArr.push(menu);
          }
        } else if (
            menu.profileTypes && menu.status === "ACTIVE" &&
            (menu.profileTypes.includes("ADULT") ||
              menu.profileTypes.includes("MASTER") || menu.profileTypes.includes("ALL"))
          ) {
            this.menuListArr.push(menu);
          }
        else if ( menu.profileTypes  && menu.status === "ACTIVE" &&
            (menu.profileTypes.includes("ADULT") ||
              menu.profileTypes.includes("MASTER") || menu.profileTypes.includes("ALL"))) {
                this.itemList.push(menu); 
              } 
      });
			if (this.subscriptions && this.subscriptions.length != 0) {
				//alert("inside-sub")
        let menuIndex;

        console.log("THE MENU ==== menuListArr", this.menuListArr);

        // menuListArr.forEach((menuItem, index) => {
				// 	debugger;
				// 	console.log("WWW.CHECK", menuItem.id)
        //   if (menuItem.id === "PLAN") {
        //     menuIndex = index;
        //   }
        // });

        if (menuIndex > -1) {
          this.menuListArr.splice(menuIndex, 1);
        }
      }
      this.menuList = this.menuListArr;
			console.log("CHECK-2",this.menuList)
    },

    filterMenuItems() {
			if (this.appConfig.featureEnabled && this.appConfig.featureEnabled.isDeckingEnabled) {
          this.filterMenuItemsingDecking();
          return;
      }
      // Menulist update from appconfig menu.
      let menuItemsList = this.appConfig.menu;

      let currentProfileDetails = localStorage.getItem("subscriberProfileDetails");

      if (currentProfileDetails) {
        currentProfileDetails = JSON.parse(currentProfileDetails);
        this.subscriberProfileDetails = currentProfileDetails;
      }

      //const menuListArr = [];
      this.menuListArr = []
      menuItemsList.forEach((menu) => {
        if (this.subscriberid && this.subscriberProfileDetails && this.subscriberProfileDetails.kidsmode === "YES") {
          if (menu.profileTypes && menu.profileTypes.includes("KIDS")) {
            this.menuListArr.push(menu);
          }
        } else {
          if (menu.profileTypes && (menu.profileTypes.includes("ADULT") || menu.profileTypes.includes("MASTER"))) {
            this.menuListArr.push(menu);
          }
        }
      });

      console.log("THE MENU ==== menuListArr --- 1", this.menuListArr);

      if (this.subscriptions && this.subscriptions.length <= 0 && this.profileid !== this.subscriberid) {
        let menuIndex;

        console.log("THE MENU ==== menuListArr", this.menuListArr);

        this.menuListArr.forEach((menuItem, index) => {
          if (menuItem.id === "PLAN") {
            menuIndex = index;
          }
        });

        if (menuIndex > -1) {
          this.menuListArr.splice(menuIndex, 1);
        }
      }

      this.menuList = this.menuListArr;
    },

    filterMovieMenu() {
      //checking if movie is available or not.
      this.menuList.forEach((el, index) => {
        if (this.isMovieEnabled === "No" && el.id === "MOVIES") {
          this.menuList.splice(index, 1);
        }
      });
    },

    getNotificationCount() {
      const notifications = JSON.parse(localStorage.getItem("notifications"));

      const deviceId = localStorage.getItem("random");

      let notificationsArr;

      if (notifications && this.subscriberid) {
        notificationsArr = notifications[this.subscriberid];
      } else if (notifications && deviceId) {
        notificationsArr = notifications[deviceId];
      }

      this.notificationCount = 0;

      if (notificationsArr && notificationsArr.length > 0) {
        notificationsArr.forEach((el) => {
          if (!el.isRead) {
            console.log("THE ELLLL", el);
            this.notificationCount++;
          }
        });
      }
    },

    getSubscriptionList() {
			//alert("called subscription")
      this.subscriptions = [];
      let payload = {
        subscriptionstatus: "ALL",
      };
      this.listSubscription(payload)
        .then((response) => {
					//alert("got subscription response")
          if (response.data.reason) {
            this.subscriptions = [];
						let couponCode = localStorage.getItem("campaign-coupon");
						if (couponCode && this.appConfig.campaign && this.appConfig.campaign.isCampaignEnbaled) {
							this.$router.push("/viewPlanAndCoupon/"+ this.appConfig.campaign.planid+"/"+couponCode);
							localStorage.removeItem("campaign-coupon");
						}
          } else {
            this.subscriptions = response.data.data;
          }

          if (this.subscriberid && this.subscriptions.length != 0) {
						//alert("CHECK_NEW_IN_SUB")
						let menuIndex;
            // this.fetchDataItem();
				// 		  menuListArr.forEach((menuItem, index) => {
				// 	debugger;
				// 	console.log("WWW.CHECK", menuItem.id)
        //   if (menuItem.id === "PLAN") {
        //     menuIndex = index;
        //   }
        // });
				//     if (menuIndex > -1) {
        //   menuListArr.splice(menuIndex, 1);
        // }
            // this.menuList = this.menuList.filter((menu) => {
            //   if (menu.title.default !== "Subscriptions") {
            //     return menu;
            //   }
            // });
						//debugger;
				 this.menuListArr.forEach((menuItem, index) => {
          if (menuItem.id.includes("Subscriptions")) {
            menuIndex = index;
          }
        });

        if (menuIndex > -1) {
          this.menuListArr.splice(menuIndex, 1);
        }
				this.menuList = this.menuListArr;


            console.log("THE LOGGGG ---> ",this.$router.currentRoute.fullPath === '/viewAllPlans');
            if(this.$router.currentRoute.fullPath === '/viewAllPlans') {
              this.$router.push("/");

              showSnackBar(this.$t("Already Subscribed"));
            }
          } else {
            if(this.$router.currentRoute.fullPath === '/viewAllPlans') {

              if (this.getPlanDeeplinkPath) {
                this.$router.push(this.getPlanDeeplinkPath);
              }else {
                 this.$router.push({ name: "Home" });
              }
            }
          }
        })
        .catch((error) => console.log(error));
    },

    // fetchDataItem() {
    //   this.menuList.push(this.subscriptionDataMenu);
    //   this.fetchMenuList();
    // },
    // fetchMenuList() {
    //   let isSubscription = this.subscriptionList && this.subscriptionList.length > 0 ? true : false;

    //   if (isSubscription) {
    //     this.menuList = this.menuList.filter((menu) => {
    //       if (menu.title.default !== "Subscriptions") {
    //         return menu;
    //       }
    //     });
    //   }
    // },

    changeLanguage() {

      // eventBus.$emit("changeLanguageView");
      this.$i18n.locale = this.$i18n.locale === "ara" ? "eng" : "ara";

      if (this.$router.currentRoute.name == "partner-switching") {
        console.log("localdisplaying language", this.localDisplayLang);
        if (this.localDisplayLang == "ara") {
          this.$router.push({
            name: "partner-switching",
            params: { lang: "en", id: this.$router.currentRoute.params.id },
          });
          this.setRtl(false);
        } else {
          this.$router.push({
            name: "partner-switching",
            params: { lang: "ar", id: this.$router.currentRoute.params.id },
          });
          this.setRtl(true);
        }
      } else if (this.$router.currentRoute.name === "telcoPaymentHandler") {
        if (this.localDisplayLang == "ara") {
          this.$router.push({
            name: "telcoPaymentHandler",
            params: { lang: "en", referenceid: this.$router.currentRoute.params.referenceid },
          });
          this.setRtl(false);
        } else {
          this.$router.push({
            name: "telcoPaymentHandler",
            params: { lang: "ar", referenceid: this.$router.currentRoute.params.referenceid },
          });
          this.setRtl(true);
        }
      } else {
        if (this.$i18n.locale === "eng") {
          this.setRtl(false);
        } else if (this.$i18n.locale === "ara") {
          this.setRtl(true);
        }
      }

      localStorage.setItem("setDisplayLanguageCode", this.$i18n.locale);
      this.localDisplayLang = this.$i18n.locale;

      this.actChangeMetaData(this.localDisplayLang);

      eventBus.$emit("check-local-storage", this.$i18n.locale);
      eventBus.$emit("check-for-series-route", this.$i18n.locale);
      eventBus.$emit("tc-pp", this.$i18n.locale);
      // eventBus.$emit("changeArabicCTAB");
      if (localStorage.getItem("isMobile")) {
        sessionStorage.setItem("isActiveDesktopView", true);
      }
      let currentUrl = window.location.href;
      if (this.$i18n.locale == 'ara') {
       currentUrl =  currentUrl.replace('/en', '/ar')
      } else {
       currentUrl = currentUrl.replace('/ar', '/en')
      }
      
      window.location.href = currentUrl;
    },
    routeHome() {
      if (this.$router.currentRoute.name === "Home") {
        eventBus.$emit("loadCategory", this.appConfig.menu[0]);
      } else {
        let currentLanguage = this.getCurrentLanguageUrlBase();

        this.$router.push({name: 'Home', params: {lang: currentLanguage} });
      }
    },
    actChangeMetaData(data) {
      let _localDisplayLang = data;

      if (_localDisplayLang === "ara") {
        document
          .getElementById("meta-desc")
          .setAttribute(
            "content",
            "نوربلاي هو موقع  يقدم خدمة المشاهدة اونلاين لمجموعة متنوعة من المسلسلات العربية ، التركية، الأجنبية والأفلاممنهافي نوربلاي، نهتم بنشر محتوى محافظ وذو قيمة حيث يتم الاختيار بعناية ليناسب جميع أفرادالعائلة"
          );
        document
          .getElementById("meta-key")
          .setAttribute(
            "content",
            "نوربلاي ، النور، النور تي في، مسلسلات جديدة، مسلسلات تركية، مسلسلات قديمة ، أرطغرول،خدمة المشاهدة أونلاين، ترفيه ، أفلام، برامج للأطفال، كرتون،مشاهدة آمنة، شاهد في أي مكان ةفي أي وقت، حمل الحلقات ،حلقات حصرية من بعد عرضها مباشرة على التلفاز،حلقات مترجمة للعربية والانجليزية، مسلسلات عربية"
          );
        window.document.title =
          "نوربلاي- شاهد محتوى آمن لك ولجميع أفراد عائلتك من مسلسلات حصرية وأفلام وبرامج للأطفال اونلاين";
      } else {
        document
          .getElementById("meta-desc")
          .setAttribute(
            "content",
            "At Noorplay we care about conservative content and family values. Every content is carefully picked and edited to suit all family members.Noor Play is a streaming service that offers a wide variety of TV shows and more"
          );
        document
          .getElementById("meta-key")
          .setAttribute(
            "content",
            "noorplay, alnoor, turkish series, kids, arabic series, the ottoman, Kurulus Osman, series, movies, entertainment"
          );
        window.document.title = "Noor Play - unlimited TV shows and more";
      }
    },
    //storing input value in a variable
    inputSuggestions(event) {
      this.searchValue = event.target.value;
    },
    //branch initialization
    getBranchDeepLinks() {
      let self = this;
      branch.init("key_live_og9iwfe3yWP2nQHNfYVVOcbmCvaoMJd4", function(err, data) {
        if (data && data.data_parsed["+is_first_session"] === true) {
          let medium = data.data_parsed["~channel"];

          let share = {
            Site: {
              AcquistionType: medium,
            },
          };

          clevertap.profile.push(share);
        }

        if (data && data.data_parsed.$desktop_deeplink_path) {
          let deeplinkPath = data.data_parsed.$desktop_deeplink_path;
          console.log("deeplinkPath", deeplinkPath);

          let getSlash = deeplinkPath.lastIndexOf("/");
          let lastItem = deeplinkPath.substring(getSlash + 1);
          console.log("lastItem", lastItem);

          let splittedDeeplink = [];
          splittedDeeplink = deeplinkPath.split("/");

          let deeplinkType = splittedDeeplink[splittedDeeplink.length - 2];
          console.log("deeplinkType", deeplinkType);
          if (deeplinkType === "details") {
            console.log("entered");
            // self.$router.push(`content/details/${lastItem}`);
            self.sharedContentId = lastItem;
            self.toggleSharedContent(true);
            console.log("ended");
          }
        }
        // self.$router.push("content/details/3yVYYkssUN1S");
      });
    },
    deepLinking() {
      //deep linking
      let currentroute = this.$router.currentRoute;

      if (currentroute && currentroute.params) {
        // share content
        if (
          currentroute.params &&
          currentroute.params.link == "content" &&
          currentroute.query.contentid &&
          currentroute.query.coupon == undefined
        ) {
          // window.history.pushState({}, document.title, "/");
          //
          this.sharedContentId = currentroute.query.contentid;
          this.toggleSharedContent(true);
        }
        // // payment content
        // if (
        //   currentroute.params &&
        //   currentroute.params.link == "content" &&
        //   currentroute.query.contentid &&
        //   currentroute.query.coupon !== undefined
        // ) {
        //   window.history.pushState({}, document.title, "/");
        //   //
        //   let data = {
        //     contentid: currentroute.query.contentid,
        //     coupon: currentroute.query.coupon,
        //   };
        //   if (this.subscriberid) {
        //     EventBus.$emit("PaymentPopup", data);
        //   } else {
        //     data.popup = "PaymentPopup";
        //     EventBus.$emit("LoginPopup", data);
        //   }
        // }
        // // registration or signup
        // if (currentroute.params && currentroute.params.link == "registration") {
        //   window.history.pushState({}, document.title, "/");
        //   //
        //   EventBus.$emit("SignPopup");
        // }
      }
    },
    getSubscriberDetails() {
      // this.fetchMenuList();
      if (localStorage.getItem("subscriberDetails")) {
        this.subscriberDetails = JSON.parse(localStorage.getItem("subscriberDetails")).data;
      }
    },

    toggleCancelReasonPopup(value) {
      this.isCancelReasonPopup = value;

      console.log("THIS IS TOGGLE CANCEL POP UP", value);
    },

    showMenu() {
      //do click action based on different screen sizes.
      if (window.innerWidth < 992) {
        this.isMobile = true;
      } else {
        this.isShow = !this.isShow;
      }
      // console.log("yes------->", this.isShow);
    },
    closeMobileMenu(response) {
      this.isMobile = response.state;

      if (response.change) {
        setTimeout(() => {
          this.changeLanguage();
        }, 500);
      }
    },
    closeMenu() {
      this.showMenu();
    },
    showMobileMenu() {
      this.isMobile = !this.isMobile;
    },
    searchInputContent(input) {
      if (input && input.length >= 3) {
        this.showSearch = true;
        eventBus.$emit("show-light-overlay", true);
        
        let validInput = input.toLowerCase();

        validInput = validInput.trim();

        let payload = {
          query: validInput,
          displaylanguage: this.localDisplayLang,
          page: 1,
        };

        if (this.subscriberProfileDetails && this.subscriberProfileDetails.kidsmode === "YES") {
          payload.genre = "Kids";
        }

        eventBus.$emit("search-api-call", payload);

        //analytics detail object.
        let data = {
          Keyword: this.searchValue,
        };

        //clevertap event.
        this.searchedCleverEvent(data);

        //google analytics Event.
        this.searchEvent(data);
      } else {
        this.showSearch = false;
        eventBus.$emit("show-light-overlay", false);
      }
    },
    // searchContent() {
    //   if (this.searchValue !== "" && this.searchValue.length > 3) {

    //     this.showSearch = true;
    //     eventBus.$emit("show-light-overlay", true);

    //     let directSearchObj = {
    //       query: this.searchValue,
    //       displaylanguage: this.localDisplayLang,
    //       page: 1
    //     };

    //     eventBus.$emit("search-api-call", directSearchObj);

    //     //logging search google analytics Event.
    //     let GA = {
    //       Keyword: this.searchValue
    //     };
    //     this.searchEvent(GA);
    //   }
    // },
    showInputText(val) {
      this.searchValue = val;
    },
    searchSelect(payload) {
      this.searchCat = payload.disCat;
      this.filterCat = payload.apiCat;
      this.showSearchDrop = false;
    },
    rotate(val) {
      if (this.isProfile || this.showSearchDrop) {
        document.getElementById(val).classList.remove("rotate-reverse");
        document.getElementById(val).classList.add("rotate");
      } else {
        document.getElementById(val).classList.remove("rotate");
        document.getElementById(val).classList.add("rotate-reverse");
      }
    },
    closeAuthPopup(state) {
      this.showAuthPopup = state;
      document.getElementById("body").style.overflowY = "auto";
    },

    toggleErrorMessagePopup(param) {
      this.showErrorMessagePopup = param.state;
      this.messageData = param.messageData;
      document.getElementById("body").style.overflowY = "auto";
    },
    closeForgotPassword(state) {
      this.showForgotPassword = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    loginSuccess(state) {
      this.showAuthButtons = state;
      this.closeAuthPopup(state);
    },
    logoutSuccess(state) {
      this.showAuthButtons = state;
    },
    toggleProfileDrop() {
      this.isNotify = false;
      this.isProfile = !this.isProfile;
    },
    toggleSearchDrop() {
      this.showSearchDrop = !this.showSearchDrop;
    },
    toggleNotificationDrop(value) {
      this.isNotify = value;
    },
    toggleAuthPopUp(state) {
      this.showAuthPopup = true;
      this.authInfo.formType = state;
      setTimeout(() => {
        // eventBus.$emit("show-login-popup", state);
        document.getElementById("body").style.overflowY = "hidden";
      }, 5);
    },
    toggleForgotPassword(state) {
      this.showAuthPopup = false;
      this.showForgotPassword = state;
    },
    toggleFacebookEmail(state) {
      this.isFacebookEmail = state;
    },
    toggleSharedContent(state) {
      this.isSharedContent = state;
    },
    toggleSwitchProfile(state) {
      this.isSwitchProfile = state;
    },
    togglePaymentPopup(state) {
      console.log("toggle pYMENT POPUP closepopup after coupn apply", state);
      this.isPayment = state;
			if (this.subscriberid) {
      this.getSubscriptionList();
   	 }
      this.filterMenuItems();
    },
    toggleNoorPayment(state) {
      this.isNoorPayment = state;
    },
    toggleCheckoutPopup(state) {
      this.isCheckout = state;
    },
    toggleSubscribePopup(state) {
      this.isSubscribe = state;
      console.log("THIS IS SUB POP UP LOG ---", state);
    },
    toggleContentSharePopup(state) {
      //load component lazy.
      this.showshareContent = state;
    },
    toggleContentDetail(state) {
      this.isContentDetail = state;
    },
    toggleTelcoOTP(state) {
      this.isTelcoOTP = state;
    },
    showSmallSearch() {
      this.showHamBrands = false;
      this.mainHeaderClass = false;
    },
    coverSmallSearch() {
      this.showHamBrands = true;
      this.mainHeaderClass = true;
    },
    checkScreenWidth() {
      if (screen.width > 992) {
        if (this.showHamBrands) {
          this.showHamBrands = false;
          this.mainHeaderClass = true;
        }
      } else {
        this.coverSmallSearch();
      }
    },

    toggleSuccessMessagePopup(val) {
      if (val) {
        this.isShowSubscriptionPopup = true;
      } else if (!val) {
        this.isShowSubscriptionPopup = false;
      }
    },
    menuSelect(index) {
			if (this.appConfig.featureEnabled && this.appConfig.featureEnabled.isDeckingEnabled) {
		this.menuSelectDecking(index);
		return;
      }
      console.log("THE MENU LIST --- > ", index, this.menuList[index]);
      let currentLanguage = this.getCurrentLanguageUrlBase();
      // this.selectedSection = index;
      let menuDetail = this.menuList[index];
       let lowerCaseSection = (menuDetail.id).toLowerCase()
      switch (menuDetail.id) {
        
        case "HOME":
          this.$router.push({ name: "Home", params: { menuDetail, lang: currentLanguage } });
          break;
        case "SERIES":
          // this.$router.push({ name: "series", params: { menuDetail } });
          this.$router.push({ name: "section-screen", params: { section: lowerCaseSection, lang: currentLanguage } });
          break;
        case "TVSHOWS":
          // this.$router.push({ name: "series", params: { menuDetail } });
          this.$router.push({ name: "section-screen", params: { section: lowerCaseSection, lang: currentLanguage} });
          break;
        case "MOVIES":
          // this.$router.push({ name: "movies", params: { menuDetail } });
          this.$router.push({ name: "section-screen", params: { section: lowerCaseSection, lang: currentLanguage } });
          break;
        case "KIDS":
          // this.$router.push({ name: "kids", params: { menuDetail } });
          this.$router.push({ name: "section-screen", params: { section: lowerCaseSection, lang: currentLanguage } });
          break;
        case "WATCHLIST":
          this.$router.push({ name: "Watchlist", params: { menuDetail, lang: currentLanguage } });
          break;
        case "PLAN":
          let payload = { listType: "onlySubscribe" };
          localStorage.setItem("payment_source", "Menu");
          // eventBus.$emit("subscribePopup", payload);
          // this.$router.push("/viewAllPlans");
          this.$router.push({ name: "seeAllPlans", params: { menuDetail, lang: currentLanguage } });

          break;
        case "HOME-KIDS":
          this.$router.push({ name: "Home", params: { menuDetail } });
          break;
      }
    },
	 menuSelectDecking (index) {
      if (this.isLanguageSwitchEnabled == true) {
        this.isLanguageSwitchEnabled = false;
      }
      let currentLanguage = this.getCurrentLanguageUrlBase();
      let moreMenuDetail = this.menuList[index]
       let sectionTitle = moreMenuDetail.title.eng.replaceAll(' ','').toLowerCase();
       if (sectionTitle == 'home') {
        this.$router.push({
            name: "Home",
            params: {lang: currentLanguage},
          });
       }else if(sectionTitle == 'mylist'){
				  this.$router.push({ name: "Watchlist", params: { moreMenuDetail, lang: currentLanguage } });
			 }else if(sectionTitle == 'subscriptions'){
				let payload = { listType: "onlySubscribe" };
          localStorage.setItem("payment_source", "Menu");
          // eventBus.$emit("subscribePopup", payload);
          // this.$router.push("/viewAllPlans");
          this.$router.push({ name: "seeAllPlans", params: { moreMenuDetail, lang: currentLanguage } });
			 }
			  else {
        this.$router.push({
            name: "section-screen",
            params: { section: sectionTitle, lang: currentLanguage },
          });
       }
   
          this.hideList = false;
 
    },
  },
  beforeDestroy() {
    eventBus.$off("switchProfile");
    eventBus.$off("logout-success");
    eventBus.$off("showForgotPassword");
    eventBus.$off("facebookemail");
    eventBus.$off("closeFacebookEmail");
    eventBus.$off("paymentPopup");
    eventBus.$off("noorPayments");
    eventBus.$off("showCheckout");
    eventBus.$off("subscribePopup");
    eventBus.$off("showContentDetail");
    eventBus.$off("telcoOtpPopup");
    eventBus.$off("toggle-profile-drop");
    eventBus.$off("show-content-share");
    eventBus.$off("toggle-SearchInput");
    eventBus.$off("getNotificationsHeaderCount");
    eventBus.$off("toggle-cancel-reason-popup");
    eventBus.$off("close-forgot-password-popup");
    eventBus.$off("close-payment-popup");
  },
  components: {
    AuthPopup: () => import(/* webpackChunkName: "header" */ "@/components/Popups/Authentication/userAuth.vue"),
    ErrorMessage: () => import(/* webpackChunkName: "header" */ "@/components/Popups/message.vue"),
    ForgotPassword: () => import(/* webpackChunkName: "fgtpwd" */ "@/components/Popups/ForgotPassword.vue"),
    Menu: () => import(/* webpackChunkName: "menu" */ "@/components/Templates/Menu.vue"),
    Search: () => import(/* webpackChunkName: "search" */ "@/components/Templates/Search.vue"),
    SearchDrop: () => import(/* webpackChunkName: "search" */ "@/components/Templates/SearchDropdown.vue"),
    ProfileDrop: () => import(/* webpackChunkName: "profileDrop" */ "@/components/Templates/profile.vue"),
    NotificationDrop: () =>
      import(/* webpackChunkName: "notificationDrop" */ "@/components/Templates/notification.vue"),
    facebookEmail: () => import(/* webpackChunkName: "facebookEmail" */ "@/components/Popups/facebookemail.vue"),
    sharedContent: () => import(/* webpackChunkName: "facebookEmail" */ "@/components/Popups/sharedContent.vue"),
    // switchProfilePin: () =>
    //   import(/* webpackChunkName: "switchProfilePin" */ "@/components/Popups/ProfilePopups/switchProfilePin.vue"),
    payment: () => import(/* webpackChunkName: "payment" */ "@/components/Popups/Payment/Payment.vue"),
    NoorPayment: () => import(/* webpackChunkName: "Noor Payment" */ "@/components/Popups/NoorPayments/payment.vue"),
    subscribe: () => import(/* webpackChunkName: "subscribe" */ "@/components/Popups/Subscribe.vue"),
    mobileMenu: () => import(/* webpackChunkName: "mobilemenu" */ "@/components/Templates/mobileMenu.vue"),
    contentShare: () => import(/* webpackChunkName: "contentshare" */ "@/components/Popups/contentShare.vue"),
    overlayLight: () => import(/* webpackChunkName: "overlayLight" */ "@/components/Overlay/Overlay.vue"),
    checkout: () => import(/* webpackChunkName: "checkout" */ "@/components/gateways/checkout.vue"),
    contentDetail: () => import(/* webpackChunkName: "contentDetail" */ "@/components/Popups/contentDetail.vue"),
    telcoOtp: () => import(/* webpackChunkName: "telcoOtp" */ "@/components/Popups/telcoOtp.vue"),
    SubscriptionCancelReason: () =>
      import(/* webpackChunkName: "telcoOtp" */ "@/components/Popups/SubscriptionCancelReason.vue"),
    // imageItem: () =>
    //   import(
    //     /* Webpack ChunkName: "imageItem" */ "@/components/Templates/imageItem.vue"
    //   )
    SubscriptionSuccessMessage : () => import(/* webpackChunkName: "SubscriptionSuccessMessage" */ "@/components/Popups/SubscriptionSuccessMessage.vue"),
  },
  mixins: [Utility, googleAnalytics, cleverTap, branchIO, FirebaseActions],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.header {
  background: $primary-color;
  position: fixed;
  top: 0;
  z-index: 888;
  width: 100%;
  height: 60px;

  .notification-bell {
    // display: inline-flex;
    // justify-content: center;
    // align-items: center;
    cursor: pointer;
    padding: 8px;

    position: relative;

    margin: 0 10px;

    .notify-icon {
      width: 20px;
      height: 22px;
    }
    .notification-dot {
      background-color: #560C1D;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 18px;
      font-size: 0.75rem;
      color: black;
      padding: 1px 4px;
      font-weight: bold;
      font-family: $font-helvetica-medium;
    }
  }

  .only-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);
    padding: 0rem 1.2rem;
  }
  &-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // grid-template-columns: 25% 50% 25%;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);
    padding: 1rem;
    &-center {
      display: none;
      .hamburger-icon {
        width: 30px;
      }
      .noorPlay_logo {
        height: 28px;
        width: 75px;
        cursor: pointer;
      }
    }
    &-brand {
      // margin-top: 0.4rem;
      ul {
        position: relative;
        display: flex;
        align-items: center;
        li {
          .logo {
            // margin: 0.3rem 0 0 0.6rem;
            .vlive-logo {
              height: 28px;
              width: 67px;
              cursor: pointer;
            }
            .net5-logo {
              height: 25px;
              width: 70px;
              cursor: pointer;
            }
            .nammaflix-logo {
              height: 50px;
              width: 90px;
              cursor: pointer;
            }
            .noorPlay_logo {
              height: 36px;
              width: auto;
              cursor: pointer;
            }
          }
          .header-text {
            margin: 0 10px;
            li {
              font-family: $font-helvetica-medium;
              color: #efeff4;
              font-size: 1rem;
              font-weight: 600;
              letter-spacing: 0;
              line-height: 22px;
              text-align: right;
              // margin-right: 15px;
              cursor: pointer;
              text-transform: capitalize;
              text-wrap: nowrap;
              p {
                margin: 0 0.7rem;
              }
              &:hover {
                //color: $primary-btn-color;
               // linear-gradient(to right, #8B1836, #380510);
								background: linear-gradient(to right, #8B1836,#8B1836, #380510);
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
              }
            }
          }
        }
      }
    }
    &-right {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      &-search {
        display: flex;
        height: 35px;
        width: 270px;
        border: 1px solid #212121;
        border-radius: 4px;
        // margin-top: 0.3rem;
        font-weight: 600;
        font-family: $font-regular;
        font-size: 0.8rem;
        &-type {
          flex-grow: 1;
          border-right: 1px solid $secondary-color;
          padding: 0.5rem;
          text-align: center;
          cursor: pointer;
          span {
            margin-right: 0.6rem;
          }
          .rotate {
            transition: all 0.2s ease-in-out;
            transform: rotate(-180deg);
          }
          .rotate-reverse {
            transition: all 0.2s ease-in-out;
            transform: rotate(0deg);
          }
          img {
            opacity: 0.5;
            width: 30px;
            height: auto;
            &:hover {
              opacity: 1;
            }
          }
        }
        &-content {
          flex-grow: 2;
          display: flex;
          &:hover {
            border: 1px solid #ffffff;
            border-radius: 4px;

            // outline: 1px solid rgba(255, 255, 255, 0.3) !important;
          }
          #searchInput {
            height: 100%;
            width: 100%;
            padding: 0.7rem;
            margin: 0;
            border: none;
            // border-right: 1px solid #212121;
            color: #ffffff;
            font-weight: 600;
            background-color: rgba(13, 13, 13, 0.5);
          }
          ::placeholder {
            font-weight: 500;
          }
          img {
            opacity: 0.5;
            padding: 0.5rem;
            cursor: pointer;
            height: 2.4rem;
            padding-bottom: 12px;
            &:hover {
              opacity: 1;
              transition: all 0.2s ease-in-out;
            }
          }
          &-back {
            display: none;
          }
        }
      }
      &_locale {
        border: 1px solid #212121;
        height: 35px;
        padding: 0.2rem 0.5rem !important;
        font-family: $font-helvetica-medium;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 1.2rem;
          margin-right: 5px;
        }
        span {
          font-size: 0.8rem;
          margin-right: 5px;
        }
        &:hover {
          border: 1px solid #ffffff;
          span {
            color: #ffffff;
          }
        }
      }
      &-auth {
        display: flex;
        &-mobile-search {
          display: none;
          margin: 8px 10px 0 0;
          img {
            width: 16px;
          }
        }
        &-nav-btn {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .login {
            width: 100%;
            padding: 0.3rem 1rem;
            font-size: 0.7rem;
            font-weight: 600;
            // margin-right: 0.3rem;
          }
          .signup {
            padding: 0.2rem 0.5rem;
            border: none;
            background: transparent;
            font-family: $font-regular;
            font-size: 0.8rem;
            font-weight: 600;
            color: $color-white;
            opacity: 0.5;
            cursor: pointer;
            &:focus {
              outline: none;
            }
          }
        }
        &-info {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          ul {
            position: relative;
            display: flex;
            .smallSearch {
              display: none;
              .go-Original {
                color: white;
                font-family: $font-regular;
              }
            }
            .py-10 {
              padding: 0 0.6rem;
            }
            li {
              transition: all 0.5s ease-in-out;
              .notify-icon {
                width: 20px;
                height: 22px;
              }
              .profile-icon {
                width: 24px;
                height: 24px;
              }
              .profile-info {
                font-size: 0.7rem;
                font-family: $font-regular;
                font-weight: 600;
                span {
                  font-size: 8px;
                }
              }

              .arrow-div {
                opacity: 0.5;
                &:hover {
                  opacity: 1;
                }
              }
              .rotate {
                transition: all 0.2s ease-in-out;
                transform: rotate(-180deg);
              }
              .rotate-reverse {
                transition: all 0.2s ease-in-out;
                transform: rotate(0deg);
              }
              &:first-child {
                cursor: pointer;
                margin-top: 2px;
                margin-right: 5px;
              }
              &:last-child {
                cursor: pointer;
                display: flex;
              }
            }
          }
        }
      }
    }
  }
  &-verification {
    margin: 1rem 2.4rem;
  }
}

.justifyEnd {
  justify-content: flex-end;
}

.justifyBetween {
  justify-content: space-between;
}

.mr {
  margin-right: 10px !important;
}

.mr-r {
  margin: 8px 0px 0 10px;
}

.mr-l {
  margin: 8px 10px 0 10px;
}

.active-tab {
  color: $btn-clr;
}
@media only screen and (max-width: 465px) {
  .header {
    height: 63px;
    width: 100vw !important;
  }
}

@media only screen and (max-width: 576px) {
  .header {
    &-content {
      &-center {
        justify-content: unset !important;
        .hamburger-icon {
          width: 25px;
          // margin-right: 5px;
        }
        // .noorPlay_logo {
        //   // margin-right: 10px;
        // }
      }
      &-right {
        .arrow-div {
          padding: 0 10px;
        }
      }
    }

    .login {
      width: 100%;
      // padding: 0.3rem 0.5rem;
      font-size: 0.6rem;
      font-weight: 600;
      // margin-right: 0.3rem;
    }
  }
}

@media only screen and (max-width: 992px) {
  .header {
    &-content {
      &-center {
        .noorPlay_logo {
          margin: 0 0.5rem 0 0.5rem;
        }
      }
      &-brand {
        display: none;
      }
      &-center {
        align-items: center;
        // justify-content: space-between;
      }
      .flex {
        display: flex;
      }
      .none {
        display: none;
      }
      &-right {
        justify-content: flex-end;
        width: 100%;
        &-auth-mobile-search {
          display: block;
        }
        .no-back {
          display: none;
        }
        .yes-back {
          display: block;
        }
        .hide-search {
          display: none;
        }
        .show-search {
          display: flex;
          width: 100%;
        }
        .flex-auth {
          display: flex;
        }
        .none-auth {
          display: none;
        }
      }
    }
  }
}
.header-content-right_locale {
  margin-left: 25px;
  margin-right: 25px;
}
</style>
