<template>
  <Popup
    :width="width"
    :margin="margin"
    :radiusType="radiusType"
    :authUser="authUser"
    :closePopupEvent="closePopup"
    :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
  >
    <socialLogin slot="social"></socialLogin>
    <div class="header-container-auth-forms" slot="component">
      <div class="header-container-auth-forms-auth-container">
        <div class="header-container-auth-forms-auth-container-auth-components">
          <component
            :is="authUser"
            :input="inputField"
            :inputtype="inputtype"
            :isMobileSignupAllowed="isMobileSignupAllowed"
            :formData="formData"
            @change="switchComponent"
            @closePopup="closeAuth()"
          ></component>
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
import { store } from "@/store/store";
import { mapMutations } from "vuex";

export default {
  props: {
    closePopup: {
      type: Function,
    },
    formInfo: {
      type: Object,
    },
  },
  data() {
    return {
      width: "30%",
      margin: "",
      radiusType: "full",
      authHeaderSignup: "",
      authHeaderLogin: "",
      signupWhite: "",
      loginWhite: "",
      enableLoader: false,
      inputField: "",
      inputtype: "",
      authUser: "",
      isMobileSignupAllowed: null,
      localDisplayLang: null,
      subscriptions: [],

      formData: null,
    };
  },
  watch: {
    authUser(val) {
      if (val) {
        this.sendFormType();
      }
    },
  },
  computed: {
    ...mapGetters(["getRtl", "subscriberid"]),
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    eventBus.$on("close-social-captcha", (status) => {
      if (status) {
        this.closeAuth();
      }
    });

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
    //setting margin for userauth popup based on screen width.
    if (screen.width <= 576) {
      this.margin = "40% auto";
    } else {
      this.margin = "12% auto";
    }

    // eventBus.$on("social-captch-event", (data) => {
    //   console.log("THIS IS THE SOCIAL -- EVENT -- ", data);

    //   let info = {};
    //   info.formType = "socialCaptcha";
    //   // this.formInfo = info;
    //   this.formData = info;
    // });

    console.log("THIS IS THE this.formInfo", this.formInfo);

    this.switchComponent(this.formInfo);
  },
  methods: {
 ...mapMutations(["setPairingFlag"]),
    sendFormType() {
      setTimeout(() => {
        eventBus.$emit("formType", this.authUser);
      }, 200);
    },

    switchComponent(value) {
      console.log("Switch",value)
      this.inputField = value.input ? value.input : "";
      this.inputtype = value.inputtype ? value.inputtype : "";
      this.authUser = value.formType;
      this.isMobileSignupAllowed = value.isMobileSignupAllowed;


      this.formData = value;
    },

    closeAuth() {
      if (this.formInfo.previousAction) {
        this.checkForSubscriptions()
          .then((response) => {
            if (!response.data.reason) {
              this.subscriptions = response.data.data;
            }
            this.handlePreviousAction(this.formInfo.previousAction);
          })
          .catch((error) => console.log("error", error));
      }
//this.setPairingFlag(false)
      this.closePopup();
    },

    checkForSubscriptions() {
      let payload = {
        subscriptionstatus: "ALL",
      };
      return store.dispatch("listSubscription", payload);
    },

    getUserType() {
      if (!this.subscriberid) {
        return "GUEST";
      } else if (this.subscriberid && this.subscriptions.length > 0) {
        return "SUBSCRIBED";
      } else {
        return "REGISTERED";
      }
    },

    handlePreviousAction(previousAction) {
      if (previousAction.action == "deeplink") {
        if (
          previousAction.data &&
          previousAction.data.userTypes &&
          previousAction.data.userTypes.includes(this.getUserType())
        ) {
          let planid = previousAction.data.deeplink.split("/")[4];
          this.$router.push({ name: "plandeeplink", params: { planid: planid } });
        }
      }
    },
  },

  beforeDestroy() {
    // eventBus.$off("social-captch-event")
  },

  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    socialLogin: () => import(/* webpackChunkName: "socialLogin" */ "./socialLogin/socialLogin.vue"),
    login: () => import(/* webpackChunkName: "Login" */ "./Login/login.vue"),
    signup: () => import(/* webpackChunkName: "Signup" */ "./Signup/signup.vue"),
    lookup: () => import(/* webpackChunkName: "lookup" */ "./lookUp/lookUp.vue"),
    socialCaptcha: () => import(/* webpackChunkName: "lookup" */ "./socialCaptcha.vue"),
    verifyResendCaptcha: () => import(/* webpackChunkName: "lookup" */ "./verifyResendCaptcha.vue"),
    mobile: () => import(/* webpackChunkName: "mobile" */ "./mobile/mobileVerification.vue"),
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
.loading {
  padding-top: 1rem;
}
.auth-header {
  background-color: $clr-dark-gd2;
  border-top-right-radius: 0.6rem;
  border-top-left-radius: 0.6rem;
}
.header-container {
  display: flex;
  background-color: #111111;
  justify-content: center;
  padding-top: 1.8rem;
  &-auth-common {
    font-family: $font-regular;
    color: $clr-light-gd1;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.1rem;
    padding: 1.2rem 2rem;
    cursor: pointer;
  }
  &-auth-forms {
    background-color: $clr-dark-gd2;
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    &-auth-container {
      background-color: $clr-dark-gd1;
      padding: 3rem 1.2rem;
      border-top-right-radius: 2.1rem;
      border-top-left-radius: 2.1rem;
      border-bottom-left-radius: 0.6rem;
      border-bottom-right-radius: 0.6rem;
      &-auth-components {
        width: 80%;
        margin: 0 auto;
      }
    }
  }
}
</style>
